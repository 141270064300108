<template>
  <!-- prettier-ignore -->
  <div class="location">
    <basic-text-input
      ref="inputRef"
      v-model="selectedLabel"
      manual-validation
      name="location"
      :disabled="$props.disabled"
      :label="$props.label"
      :size="$props.size"
      :supporting-text="$props.supportingText"
      :v="$props.v"
      @clear="doClear"
      @keydown="onKeyDown"
      @input.stop="search"
    >
      <template #trailing>
        <the-icon
          v-if="busy"
          class="fa-spin"
          art="duo"
          name="spinner-third"
        />
      </template>
    </basic-text-input>

    <basic-dropdown-menu
      :id="dropdownId"
      ref="dropdownRef"
      manual-focus
      name="location-dropdown"
      :options="results"
      :selected="model"
      :size="$props.size"
      @close="onClose"
      @select="selectItem"
    />
  </div>
</template>

<script setup>
import { onMounted, nextTick, reactive, ref, watch } from 'vue'
import { v4 as uuidv4 } from 'uuid'

import { ReactivityUtil } from '@/utils/Reactivity'

import masterDataAPI from '@/api/masterData'

import BasicDropdownMenu from '@/components/Basic/DropdownMenu'
import BasicTextInput from '@/components/Basic/TextInput'

// INIT
const model = defineModel({ type: [Object] })
const props = defineProps({
  contractStartDate: {
    type: String,
    required: true,
  },

  disabled: {
    type: Boolean,
    default: false,
  },

  hasTrailing: {
    type: Boolean,
    default: false,
  },

  label: {
    type: String,
    required: true,
  },

  size: {
    validator(value) {
      return ['sm', 'md'].includes(value)
    },
    default: 'sm',
  },

  supportingText: {
    type: String,
    default: undefined,
  },

  v: {
    type: Object,
    default: () => {},
  },

  value: {
    type: Object,
    default: () => {},
  },
})

// DATA
const KEY = uuidv4()
const dropdownId = `dropdown-${KEY}`
const dropdownRef = ref(null)
const inputRef = ref(null)
const selectedLabel = ref(null)
const results = reactive([])
const busy = ref(false)

// METHODS
function doClear() {
  model.value = null
}

/**
 * when the dropdown gets closed and there's no valid model, clear the selectedLabel/input
 */
async function onClose() {
  await nextTick()

  if (!model.value) {
    selectedLabel.value = null
  }
}

/**
 * handle keydown-events to set focus to opened dropdown menu
 */
function onKeyDown(event) {
  if (dropdownRef.value.isOpen) {
    switch (event.keyCode) {
      case 13:
        selectItem(results[0])
        dropdownRef.value.close()
        event.preventDefault()
        break

      case 27:
        dropdownRef.value.close()
        event.preventDefault()
        break

      case 9: // tab
      case 38: // up
      case 40: // down
        dropdownRef.value.setFocus()
        event.preventDefault()
        break
    }
  }
}

/**
 * select item and then set focus on the input-field (to restore tabbing order)
 */
function selectItem(item) {
  selectedLabel.value = item.label
  if (model.value === undefined || model.value === null) {
    model.value = item.value
  } else {
    ReactivityUtil.reAssign(model.value, item.value)
  }

  inputRef.value.inputRef.focus()
}

/**
 * trigger search with input and if there're results, open dropdown menu
 */
async function search(event) {
  model.value = null
  props.v.$reset()

  const query = event.target.value
  if (query.length > 3) {
    busy.value = true
    const locations = await searchLocation(query)
    busy.value = false
    ReactivityUtil.reAssign(results, locations)

    // has results => open menu
    if (results.length > 0) {
      dropdownRef.value.open()

      // no results => close menu
    } else {
      dropdownRef.value.close()
    }
  }
}

async function searchLocation(searchQuery) {
  const payload = {
    query: searchQuery.toString(),
    contractStartDate: props.contractStartDate,
  }
  const locations = await masterDataAPI.getLocations(payload)

  // no results
  if (locations.length === 0) {
    model.value = null
  }

  return locations.map(location => {
    return {
      value: location,
      label: location.displayName,
    }
  })
}

// WATCHERS
watch(
  model,
  value => {
    if (!value) return
    selectedLabel.value = value.displayName
  },
  { immediate: true, deep: true }
)

// LIFECYCLE HOOKS
onMounted(() => {
  if (props.value && props.value.displayName) {
    selectedLabel.value = props.value.displayName
  }
})
</script>

<style scoped>
.location {
  position: relative;

  .icon {
    color: var(--on-surface);
  }
}
</style>
