<template>
  <div
    class="screen body-2"
    :class="{ 'screen--gapless': $props.gapless, 'screen--fullWidth': $props.fullWidth }"
  >
    <div
      v-if="$slots.header"
      class="screen__header"
    >
      <div class="the-grid">
        <slot name="header" />
      </div>
    </div>
    <div class="screen__content">
      <div class="the-grid">
        <slot />
      </div>
    </div>
  </div>
</template>

<script setup>
// INIT
defineProps({
  aide: {
    type: Boolean,
    default: false,
  },

  fullWidth: {
    type: Boolean,
    default: true,
  },

  gapless: {
    type: Boolean,
    default: false,
  },
})
</script>

<style scoped>
.screen {
  display: flex;
  flex-direction: column;
  margin: 0 0 var(--dotcom-responsive-spacing-res-xxl) 0;

  &.screen--gapless {
    .screen__header {
      padding: 0;
    }

    .screen__content {
      padding: var(--dotcom-responsive-spacing-res-l) 0 0;
    }
  }
}

.screen__content {
  padding: var(--dotcom-responsive-spacing-res-l) 0 0;
  position: relative;

  &:before {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    content: '';
    height: var(--dotcom-responsive-spacing-res-l);

    background-color: var(--surface-ext-b-low);
  }

  &:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    height: var(--dotcom-responsive-spacing-res-l);
    border-radius: var(--border-radius-res-l) var(--border-radius-res-l) 0px 0px;
    background-color: var(--surface);
  }
}

.screen__header {
  background-color: var(--surface-ext-b-low);
}

.screen--fullWidth .screen__header .the-grid:deep(> *),
.screen--fullWidth .screen__content .the-grid:deep(> *) {
  grid-column: 1 / -1;
}
</style>
