<template>
  <div
    ref="contentRef"
    class="scrollable"
    @scroll="handleScroll"
  >
    <slot />
  </div>

  <div
    v-if="$props.hasGradient"
    class="scrollable__spacer"
    :class="{ 'scrollable__spacer--shadow': hasOverflow }"
  />
</template>

<script setup>
import { nextTick, onMounted, onUnmounted, ref } from 'vue'
import inRange from 'lodash/inRange'

// INIT
defineProps({
  hasGradient: {
    type: Boolean,
    default: true,
  },
})

// DATA
const hasOverflow = ref(true)
const contentRef = ref(null)
let observer

// METHODS
async function evalNeedForOverflow(el) {
  await nextTick()

  if (el) {
    hasOverflow.value = hasVerticalOverflow(el)
  }
}

function handleScroll() {
  const content = contentRef.value
  if (content && hasVerticalOverflow(content)) {
    const difference = Math.ceil(content.scrollTop) - (content.scrollHeight - content.offsetHeight)
    const hasScrolledToBottom = inRange(difference, -1, 2)
    hasOverflow.value = !hasScrolledToBottom
  }
}

function hasVerticalOverflow(el) {
  return el.scrollHeight > el.clientHeight
}

// LIFECYCLE HOOKS
onMounted(async () => {
  observer = new MutationObserver(() => {
    evalNeedForOverflow(contentRef.value)
  })
  await nextTick()
  evalNeedForOverflow(contentRef.value)
  observer.observe(contentRef.value, { attributes: true, childList: true, subtree: true })
})

onUnmounted(() => {
  observer.disconnect()
})
</script>

<style scoped>
.scrollable {
  height: auto;
  padding: 0 var(--dotcom-responsive-gutter-responsive);
  overflow-y: auto;
  overflow-x: clip;

  scrollbar-width: thin;
  scrollbar-color: var(--on-surface-medium) var(--on-primary);
}

.scrollable__spacer {
  --gradient-height: var(--fixed-spacing-fix-06); /* must be >= box-shadow-height */
  --box-shadow-height: var(--fixed-spacing-fix-06);
  min-height: var(--gradient-height);
  width: 100%;
  background-color: transparent;
  box-shadow: none;
  clip-path: inset(calc(var(--box-shadow-height) * -1) 0 var(--gradient-height) 0);
  transition-property: box-shadow;
  transition-timing-function: ease-in-out;
  transition-duration: 300ms;
}

.scrollable__spacer--shadow {
  box-shadow:
    0px -1px 6px -1px rgba(0, 0, 0, 0.12),
    0px -2px 10px 0px rgba(0, 0, 0, 0.08); /* elevation-level-01 */
}
</style>
